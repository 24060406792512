<template>
  <v-container class="mt-4 px-5" fluid>
    <v-card class="shadowCard">
    	<v-card-title primary-title>
    	  Terminos y condiciones
    	</v-card-title>
    	<v-card-text class="black--text">

    		<v-row v-if="[334,1,568,1932].includes( getdatosUsuario.iderp )">
    			<v-col cols="12" lg="4" md="6">
		    		<label>Elige escuela</label>
		    		<v-radio-group
				      v-model="escuela"
				      row
				    >
				      <v-radio
				        label="INBI"
				        :value="1"
				      ></v-radio>
				      <v-radio
				        label="FAST"
				        :value="2"
				      ></v-radio>
				    </v-radio-group>
    			</v-col>
    		</v-row>

        <v-row>
          <v-col cols="12">
           <v-alert
              text
              color="info"
            >
              <h3 class="text-h5">
                HOJA DE PROCEDIMIENTO DE VALIDACION DE PROMOCIONES PARA COMISIONES
              </h3>
              <br/>
              <div>
                Al finalizar el ingreso de los datos del alumno de promoción se le solicitara ingresar 2 documentos en fotografía los cuales deben estar cargados de manera correcta junto con el pago de alumno para poder validar su comisión   <br/><br/>

                1- INE del alumno o tutor por ambos lados donde se aprecie de manera clara su nombre y firma<br/>
                2- hoja de términos y condiciones firmada por el alumno o tutor (la firma debe coincidir con la del INE)<br/><br/>

                recuerde que si la hoja no esta Ingresada de manera correcta en sistema NO SE PODRA LIBERAR SU COMISION
              </div>
            </v-alert>

          </v-col>
        </v-row>

        <v-card-title primary-title>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click="downloadImage()"
          >
            Descargar
            <v-icon
              right
              dark
            >
              mdi-cloud-download
            </v-icon>
          </v-btn>
        </v-card-title>
	      <v-row v-if="escuela==1" dense>
	      	<v-col cols="12">
	        	<embed
	            :src="`https://escuelakpi.club/viewer/web/viewer.html?file=https://escuelakpi.club/imagenes-promociones/TERMINOS-CONDICIONES-INBI.pdf`"
	            width="100%"
	            :height="tamanioPantalla"
	          />
	      	</v-col>
	      </v-row>

        <v-row v-if="escuela==2" dense>
	      	<v-col cols="12">
	        	<embed
	            :src="`https://escuelakpi.club/viewer/web/viewer.html?file=https://escuelakpi.club/imagenes-promociones/TERMINOS FAST.pdf`"
	            width="100%"
	            :height="tamanioPantalla"
	          />
	      	</v-col>
	      </v-row>
    	</v-card-text>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
	import axios from 'axios';

  import { mapGetters, mapActions }    from "vuex";

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'

  export default {

  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

    	edad: null,
    	modalidad: null,
    	horario: null,
    	hermanos: null,

    	promociones: [],
    	escuela: null,


    }),

    created( ){

      this.escuela = this.getEscuela

    	this.initialize( )
    },

    methods:{

    	initialize( ){
				this.cargar = true;
	      this.promociones = [];
	      this.$http.get("promociones.imagenes").then((response) => {
	        this.promociones = response.data
	        console.log( this.promociones )
	        this.cargar = false;
	      }).catch((error) => {
	        this.validarError(error);
	      }).finally(() => {
	        this.cargar = false;
	      });
    	},

      async downloadImage() {

      	let nombreArchivo = this.escuela == 1 ? 'TERMINOS-CONDICIONES-INBI.pdf' : 'TERMINOS FAST.pdf'

    		let link = 'https://escuelakpi.club/kpi/imagenes-promociones/' + nombreArchivo

    		window.open(link, '_blank');
        
      },

    },

    computed:{
      ...mapGetters('login',['getEscuela','getdatosUsuario']),

    	url( ){

    		let nombreArchivo = this.escuela == 1 ? 'TERMINOS-CONDICIONES-INBI.pdf' : 'TERMINOS FAST.pdf'

    		let link = 'https://escuelakpi.club/kpi/imagenes-promociones/' + nombreArchivo

    		window.open(link, '_blank');

        return link

    	},

    	tamanioPantalla () {
        return this.$vuetify.breakpoint.height -185
      },

      width(){
        return this.$vuetify.breakpoint.width
      },

      height(){
        return this.$vuetify.breakpoint.height - 250
      }
    }
  }
</script>

<style>
  body {
  margin: 0;
  font-family: sans-serif;
  font-size: 0.9rem;
}
#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
#toolbar {
  display: flex;
  align-items: center;
  background-color: #555;
  color: #fff;
  padding: 0.5em;
}
#toolbar button,
#page-mode input {
  color: currentColor;
  background-color: transparent;
  font: inherit;
  border: 1px solid currentColor;
  border-radius: 3px;
  padding: 0.25em 0.5em;
}
#toolbar button:hover,
#toolbar button:focus,
#page-mode input:hover,
#page-mode input:focus {
  color: lightGreen;
}
#page-mode {
  display: flex;
  align-items: center;
  padding: 0.25em 0.5em;
}

#viewport-container {
  flex: 1;
  background: #eee;
  overflow: auto;
}


#viewport {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#viewport > div {
  text-align: center;
  max-width: 100%;
}
#viewport canvas {
  width: 100%;
  box-shadow: 0 2px 5px gray;
}

#viewerContainer::-webkit-scrollbar {
  -webkit-appearance: none !important;
}

.#viewerContainer > #viewerContainer::-webkit-scrollbar:vertical {
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar:vertical{
  width: 8px !important;
}

#viewerContainer::-webkit-scrollbar-thumb{
  background-color: #BABCBF;
  border-radius: 20px;
  border: 1px solid #ededed;
}
.custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
